// import React from "react";
import React, { forwardRef, MouseEventHandler, useState } from "react";
import ReactLoading from "react-loading";
// Styles import
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { DSColors } from "../../../../styles/variables";

export interface CTAButtonProps {
  name?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  id?: string;
  category?: "primary" | "secondary" | "tertiary";
  family?: "vitee" | "becqe" | "danger";
  iconSrc?: string;
  style?: React.CSSProperties;
  isbuttonhoovered?: boolean;
  size?: "M" | "L";
  isLoading?: boolean;
  type?: "button" | "submit";
  form?: string;
  isGap?: boolean;
  dataTestId?: string;
}

export interface IconWrapperProps {
  iconsrc?: number;
  isdisabled?: number;
  category?: "primary" | "secondary" | "tertiary";
  family?: "vitee" | "becqe" | "danger";
  isbuttonhovered?: number;
  size?: "M" | "L";
}

const Button = styled.button<CTAButtonProps>`
  -webkit-transition: background-color 0.6s ease-out, color 0.6s ease-out,
    border 0.6s ease-out;
  -moz-transition: background-color 0.6s ease-out, color 0.6s ease-out,
    border 0.6s ease-out;
  transition: background-color 0.6s ease-out, color 0.6s ease-out,
    border 0.6s ease-out;
  background-color: ${(props) =>
    props.isDisabled && props.category === "primary"
      ? DSColors.SGLight04
      : props.isDisabled && props.category === "secondary"
      ? DSColors.SGLight04
      : props.isDisabled && props.category === "tertiary"
      ? DSColors.SGLight04
      : props.family === "vitee" && props.category === "primary"
      ? DSColors.Mint
      : props.family === "vitee" && props.category === "secondary"
      ? DSColors.white
      : props.family === "vitee" && props.category === "tertiary"
      ? DSColors.MintLight05
      : props.family === "becqe" && props.category === "primary"
      ? DSColors.OffBlack
      : props.family === "becqe" && props.category === "secondary"
      ? DSColors.white
      : props.family === "becqe" && props.category === "tertiary"
      ? DSColors.SGLight04
      : props.family === "danger" && props.category === "primary"
      ? DSColors.PowerRed
      : props.family === "danger" && props.category === "secondary"
      ? DSColors.white
      : props.family === "danger" && props.category === "tertiary"
      ? DSColors.PRLight03
      : DSColors.Mint};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: ${(props) => (props.size && props.size === "M" ? "37px" : "48px")};
  padding: ${(props) =>
    props.size && props.size === "M" ? "8px 16px" : "13.5px 24px"};
  cursor: pointer;
  position: relative;
  border: 1px solid
    ${(props) =>
      props.isDisabled && props.category === "primary"
        ? DSColors.SGLight04
        : props.isDisabled && props.category === "secondary"
        ? DSColors.SGLight02
        : props.isDisabled && props.category === "tertiary"
        ? DSColors.SGLight04
        : props.family === "vitee" && props.category === "primary"
        ? DSColors.Mint
        : props.family === "vitee" && props.category === "secondary"
        ? DSColors.Mint
        : props.family === "vitee" && props.category === "tertiary"
        ? DSColors.MintLight05
        : props.family === "becqe" && props.category === "primary"
        ? DSColors.OffBlack
        : props.family === "becqe" && props.category === "secondary"
        ? DSColors.OffBlack
        : props.family === "becqe" && props.category === "tertiary"
        ? DSColors.SGLight04
        : props.family === "danger" && props.category === "primary"
        ? DSColors.PowerRed
        : props.family === "danger" && props.category === "secondary"
        ? DSColors.PowerRed
        : props.family === "danger" && props.category === "tertiary"
        ? DSColors.PRLight03
        : DSColors.Mint};
  gap: ${(props) => (props.isGap ? "10px" : "0px")};
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: ${(props) =>
    props.isDisabled
      ? DSColors.SGLight02
      : props.family === "vitee" && props.category === "primary"
      ? DSColors.white
      : props.family === "vitee" &&
        props.category === "secondary" &&
        !props.isbuttonhoovered
      ? DSColors.Mint
      : props.family === "vitee" &&
        props.category === "secondary" &&
        props.isbuttonhoovered
      ? DSColors.MintDark01
      : props.family === "vitee" && props.category === "tertiary"
      ? DSColors.Mint
      : props.family === "becqe" && props.category === "primary"
      ? DSColors.white
      : props.family === "becqe" && props.category === "secondary"
      ? DSColors.OffBlack
      : props.family === "becqe" && props.category === "tertiary"
      ? DSColors.OffBlack
      : props.family === "danger" && props.category === "primary"
      ? DSColors.white
      : props.family === "danger" &&
        props.category === "secondary" &&
        props.isbuttonhoovered
      ? DSColors.PRDark01
      : props.family === "danger" && props.category === "secondary"
      ? DSColors.PowerRed
      : props.family === "danger" && props.category === "tertiary"
      ? DSColors.PowerRed
      : DSColors.Mint};
  > p {
    white-space: nowrap;
    letter-spacing: 0rem;
  }
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    background-color: ${(props) =>
      props.disabled
        ? DSColors.SGLight04
        : props.family === "vitee" && props.category === "primary"
        ? DSColors.MintDark01
        : props.family === "vitee" && props.category === "secondary"
        ? DSColors.white
        : props.family === "vitee" && props.category === "tertiary"
        ? DSColors.MintLight03
        : props.family === "becqe" && props.category === "primary"
        ? DSColors.StableGrey
        : props.family === "becqe" && props.category === "secondary"
        ? DSColors.SGLight04
        : props.family === "becqe" && props.category === "tertiary"
        ? DSColors.SGLight02
        : props.family === "danger" && props.category === "primary"
        ? DSColors.PRDark01
        : props.family === "danger" && props.category === "secondary"
        ? DSColors.white
        : props.family === "danger" && props.category === "tertiary"
        ? DSColors.PRLight02
        : DSColors.Mint};
    border: 1px solid
      ${(props) =>
        props.disabled && props.category === "secondary"
          ? DSColors.SGLight02
          : props.disabled && props.category === "primary"
          ? DSColors.SGLight04
          : props.disabled && props.category === "tertiary"
          ? DSColors.SGLight04
          : props.family === "vitee" && props.category === "primary"
          ? DSColors.MintDark01
          : props.family === "vitee" && props.category === "secondary"
          ? DSColors.MintDark01
          : props.family === "vitee" && props.category === "tertiary"
          ? DSColors.MintLight03
          : props.family === "becqe" && props.category === "primary"
          ? DSColors.StableGrey
          : props.family === "becqe" && props.category === "secondary"
          ? DSColors.OffBlack
          : props.family === "becqe" && props.category === "tertiary"
          ? DSColors.SGLight02
          : props.family === "danger" && props.category === "primary"
          ? DSColors.PRDark01
          : props.family === "danger" && props.category === "secondary"
          ? DSColors.PRDark01
          : props.family === "danger" && props.category === "tertiary"
          ? DSColors.PRLight02
          : DSColors.Mint};
  }
`;

const IconWrapper = styled(SVG)<IconWrapperProps>`
  display: ${(props) => (props.iconsrc === 0 ? "none" : "")};
  height: ${(props) => (props.size && props.size === "M" ? "20px" : "unset")};
  width: ${(props) => (props.size && props.size === "M" ? "20px" : "unset")};
  path {
    -webkit-transition: fill 0.6s ease-out;
    -moz-transition: fill 0.6s ease-out;
    transition: fill 0.6s ease-out;
    fill: ${(props) =>
      props.isdisabled === 1
        ? DSColors.SGLight02
        : props.category === "secondary" &&
          props.family === "vitee" &&
          props.isbuttonhovered === 1
        ? DSColors.MintDark01
        : props.category === "secondary" &&
          props.family === "danger" &&
          props.isbuttonhovered === 1
        ? DSColors.PRDark01
        : props.category !== "primary" && props.family === "vitee"
        ? DSColors.Mint
        : props.category !== "primary" && props.family === "becqe"
        ? DSColors.OffBlack
        : props.category !== "primary" && props.family === "danger"
        ? DSColors.PowerRed
        : DSColors.white};
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 - No mandatory props
 - Default props are listed below
 **/
export const CTAButton = forwardRef<HTMLButtonElement, CTAButtonProps>(
  (props, ref) => {
    const {
      name = "Primary button",
      onClick,
      isDisabled = false,
      id,
      category = "primary",
      family = "vitee",
      iconSrc = "",
      size,
      isLoading = false,
      type = "button",
      form,
      isGap = true,
      dataTestId,
      style,
    } = props;

    const [isButtonHovered, setIsButtonHovered] = useState<boolean>(false);

    return (
      <Button
        onClick={onClick}
        disabled={isDisabled || isLoading}
        isDisabled={isDisabled}
        id={id}
        category={category}
        family={family}
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
        isbuttonhoovered={isButtonHovered}
        size={size}
        type={type}
        ref={ref}
        form={form}
        isGap={isGap}
        data-testid={dataTestId}
        style={style}
      >
        <IconWrapper
          isdisabled={isDisabled ? 1 : 0}
          category={category}
          family={family}
          iconsrc={!!iconSrc ? 1 : 0}
          isbuttonhovered={isButtonHovered ? 1 : 0}
          src={iconSrc}
          size={size}
        ></IconWrapper>
        {isLoading && (
          <Loader>
            <ReactLoading
              type={"spin"}
              color={"#e4e3e3"}
              height={25}
              width={25}
            />
          </Loader>
        )}
        <p style={isLoading ? { color: "transparent" } : undefined}>{name}</p>
      </Button>
    );
  }
);
