// Libraries import
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";

// imgs import
// import React from "react";
import React, { useState } from "react";
// Styles import
import styled from "styled-components";
import { DSColors } from "../../../../styles/variables";
import { TextInput } from "../TextInput/TextInput";

export interface SearchAddressInputProps {
  onSelectLocation: ({
    lat,
    lng,
    zipCode,
    description,
  }: {
    lat: number;
    lng: number;
    zipCode: string;
    city: string;
    description: string;
  }) => void;
  isDisabled?: boolean;
  placeHolder?: string;
  label?: string;
  labelHidden?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
}

export interface SearchAddressInputWrapperProps {
  value?: string;
}

const InputWrapper = styled.div<SearchAddressInputWrapperProps>`
  width: 100%;
  max-width: 30rem;
`;

const RenderSuggestions = styled.div`
  width: 100%;
`;

const Form = styled.div`
  position: relative;
`;

const List = styled.div`
  border: 1px solid ${DSColors.SGLight04};
  border-radius: 3px;
  width: 100%;
  margin: 0px -10px 0 auto;
  display: flex;
  align-items: flex-start;
  height: fit-content;
  flex-direction: column;
  overflow: auto;
  max-height: 160px;
  position: absolute;
  background-color: rgb(255, 255, 255);
  z-index: 3;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const ListElement = styled.div`
  padding: 7px 10px;
  cursor: pointer;
  width: 100%;
  text-align: start;
  &:hover {
    background-color: ${DSColors.SGLight05};
  }
`;

export const SearchAddressInput: React.FC<SearchAddressInputProps> = ({
  onSelectLocation,
  isDisabled,
  placeHolder = "ex : 6 Rue d'Italie, 49300 Cholet",
  label = "Adresse :",
  labelHidden = true,
  isRequired = false,
  errorMessage = "",
}) => {
  const [loaded, setLoaded] = useState(false);
  const {
    value,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "fr",
      },
    },
    debounce: 300,
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoaded(true);
    const value = e.target.value;
    setValue(value);
  };

  const handleSelect =
    ({ description }: google.maps.places.AutocompletePrediction) =>
    async () => {
      try {
        setValue(description, false);
        clearSuggestions();
        const results = await getGeocode({ address: description });

        const values = await Promise.all([
          getLatLng(results[0]),
          getZipCode(results[0], false),
        ]);
        const { address_components } = results[0];
        const city = (() => {
          for (let component of address_components) {
            if (component.types.includes("locality")) {
              return component.long_name; // Return the selected city
            }
          }
          return "";
        })();
        const { lat, lng } = values[0];
        const zipCode = values[1] || "";
        onSelectLocation({
          lat: lat,
          lng: lng,
          zipCode,
          city,
          description,
        });
      } catch (error) {
        console.error(error);
      }
    };
  const NoResult = () => {
    if (status === "ZERO_RESULTS") {
      return (
        <List>
          <ListElement>
            <strong>Aucun résultat</strong>
          </ListElement>
        </List>
      );
    }
    if (loading) {
      return (
        <List>
          <ListElement>
            <strong>Chargement...</strong>
          </ListElement>
        </List>
      );
    }
    return;
  };

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <RenderSuggestions key={index}>
          <ListElement key={place_id} onClick={handleSelect(suggestion)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </ListElement>
        </RenderSuggestions>
      );
    });

  return (
    <InputWrapper value={value}>
      <TextInput
        value={value}
        type="text"
        onChange={handleInput}
        placeholder={placeHolder}
        isDisabled={isDisabled}
        iconHidden={true}
        inputLableHidden={labelHidden}
        inputDescriptionHidden={errorMessage ? false : true} // if errorMessage is empty, hide the description
        inputLableText={label}
        required={isRequired}
        error={!!errorMessage}
        inputDescriptionText={errorMessage}
      />
      {status === "OK" && loaded ? (
        <Form>
          <List>{renderSuggestions()}</List>
        </Form>
      ) : (
        NoResult()
      )}
    </InputWrapper>
  );
};
